import formUtils from '@/utils/form'

class FormsContext {
  constructor () {
    this.formRefs = {}
    this.formData = {}
    this.tempData = {}
  }

  setFormRef (name, formRef) {
    this.formRefs[name] = formRef
  }

  setFormData (name, data) {
    this.formData[name] = data
  }

  setTempData (name, data) {
    this.tempData[name] = data
  }

  composFormData () {
    let data = {}
    for (const key of Object.keys(this.formData)) {
      data = {
        ...data,
        ...this.formData[key],
      }
    }

    return data
  }

  async validateAll () {
    let allPass = true
    for (const key of Object.keys(this.formRefs)) {
      if (!await formUtils.checkForm(this.formRefs[key])) allPass = false
    }
    return allPass
  }
}

const formsContext = new FormsContext()

export default formsContext
