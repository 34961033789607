<template>
  <div v-loading="loading" class="member-shop-point-exchange-product-edit">
    <div
      v-if="!loaded"
      v-loading="!loaded"
      element-loading-background="rgba(0, 0, 0, 0)"
      class="w-full"
      style="height: 500px"
    />
    <template v-if="loaded">
      <MemberShopPointExchangeProdctEditInfoBlock class="card-container form-wrapper" :productData="productData" :context.sync="context" :isPaymentPromise.sync="isPaymentPromise" />
      <MemberShopPointExchangeProdctEditRulesBlock class="card-container form-wrapper" :productData="productData" :context="context" :isPaymentPromise.sync="isPaymentPromise" />
    </template>
    <PageFixedFooter :confirmLoading="loading" @cancel="onCancel" @confirm="onSubmit" />
  </div>
</template>

<script>
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import { defineComponent, computed, ref, onMounted, reactive } from 'vue'
import MemberShopPointExchangeProdctEditInfoBlock from './components/MemberShopPointExchangeProdctEditInfoBlock.vue'
import MemberShopPointExchangeProdctEditRulesBlock from './components/MemberShopPointExchangeProdctEditRulesBlock.vue'
import { CreatePointExchangeProduct, UpdateMemberShopProductByPointExchange, FindMemberShopProduct } from '@/api/memberShop'
import { useRoute, useRouter } from 'vue-router/composables'
import formsContext from './context'
import store from '@/store'
import { get } from 'lodash'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'MemberShopPointExchangeProdctEdit',
  components: {
    PageFixedFooter,
    MemberShopPointExchangeProdctEditInfoBlock,
    MemberShopPointExchangeProdctEditRulesBlock,
  },
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const shopId = computed(() => store.getters.shop)
    const productId = computed(() => route.params.id)
    const productData = ref({})
    const loading = ref(false)
    const loaded = ref(false)

    const context = reactive({
      type: 'classTicket',
    })
    const isPaymentPromise = ref(false)

    const findProduct = async (payload) => {
      const [res, err] = await FindMemberShopProduct({
        shopId: shopId.value,
        id: productId.value,
      })
      if (err) return window.$message.error(err)
      productData.value = res
      isPaymentPromise.value = get(res, 'isPaymentPromise')
    }

    const createProduct = async (payload) => {
      const [, err] = await CreatePointExchangeProduct(payload)
      if (err) throw err
      window.$message.success('新增商品成功！')
    }
    const updateProduct = async (payload) => {
      const [, err] = await UpdateMemberShopProductByPointExchange(payload)
      if (err) throw err
      window.$message.success('更新商品成功！')
    }

    const checkCouponExp = () => {
      const relateData = get(formsContext.tempData, 'couponData')
      const specifyExpiredDate = get(relateData, 'specifyExpiredDate') || get(relateData, 'expAt')
      if (!specifyExpiredDate) return true
      const isExp = dayjs(specifyExpiredDate).isBefore(dayjs())
      if (isExp) {
        window.$message.warning('票券/票券已過期，請重新選擇 !')
        return false
      }
      return true
    }

    const onSubmit = async () => {
      loading.value = true
      const allPass = await formsContext.validateAll()
      if (!allPass) {
        loading.value = false
        return
      }
      if (!checkCouponExp()) return
      const formData = formsContext.composFormData()
      const payload = {
        shopId: shopId.value,
        ...formData,
      }

      try {
        if (!productId.value) await createProduct(payload)
        if (productId.value) {
          payload.id = productId.value
          await updateProduct(payload)
        }
        router.push({ name: 'MemberShopPointExchangeCenter' })
      } catch (error) {
        window.$message.error(error)
        loading.value = false
      }
    }

    const onCancel = () => {
      router.push({
        name: 'MemberShopPointExchangeCenter',
      })
    }

    onMounted(async () => {
      if (productId.value) await findProduct()
      loaded.value = true
    })

    return { onCancel, onSubmit, loading, productData, loaded, context, isPaymentPromise }
  },
})
</script>

<style lang="postcss" scoped>
.member-shop-point-exchange-product-edit {
  @apply flex flex-col gap-[20px];
}
.form-wrapper {
  @apply px-[32px] py-[24px];
}
</style>
